@import "tailwindcss/base.css";
@import "tailwindcss/components.css";
@import "tailwindcss/utilities.css";

body > :not(.no-script) {
  @apply hidden;
}

.no-script-error {
  @apply mt-10 mx-auto max-w-md;

  & img {
    @apply w-20 h-20;
  }

  & h2 {
    @apply mb-4 mt-8 text-3xl tracking-tight font-bold;
    font-family: "XfinityBrown", sans-serif;
  }

  & p {
    @apply my-4;
  }

  & button {
    @apply bg-fill-neutral-base text-inverse-base rounded-small
    ring-2 ring-offset-0 ring-transparent
    ease-in duration-200 my-4 mr-2 py-3 px-6 font-bold duration-200;
    font-family: "XfinityBrown", sans-serif;

    &:hover {
      @apply bg-fill-neutral-hover text-inverse-hover;
    }
  }
}
